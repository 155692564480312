import { getApp, initializeApp } from 'firebase/app';
import { getFirestore,} from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

const firebaseConfig = {
    apiKey: "AIzaSyBBn60fefmG1YNbh6jSrCGDQNkoJ0oNIIQ",
  authDomain: "bricado.firebaseapp.com",
  projectId: "bricado",
  storageBucket: "bricado.appspot.com",
  messagingSenderId: "568768515183",
  appId: "1:568768515183:web:14f2623bfd1e3a9e83519a",
  measurementId: "G-EMT0W38BE2"
  
};

const app = initializeApp(firebaseConfig);
export  const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)
