import React from 'react'

import Select, { StylesConfig } from 'react-select';




function CustomDropDown({ options, defaultValue, onSelect, customHeaderStyle, placeHolderStyle, ...props }) {


    const colourStyles = {
        control: (styles) => ({ ...styles, ...customHeaderStyle }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            return {
                ...styles,

            };
        },
        input: (styles) => ({ ...styles,  }),
        placeholder: (styles) => ({ ...styles, ...placeHolderStyle }),
        singleValue: (styles, { data }) => ({ ...styles, color: 'white'}),
    };

    return (
        <Select
            defaultValue={defaultValue}
            options={options}
            styles={colourStyles}
            {...props}
        />
    )
}

export default CustomDropDown