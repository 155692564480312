import { ContentState, convertFromRaw, Editor, EditorState } from "draft-js";
import React from "react";
import "draft-js/dist/Draft.css";

const Descriptions = ({ desc, properties }) => {
  let bg = "#f9fafb";
  const convertStringToEditorState = (text) => {
    try {
      const rawContentState = JSON.parse(text);
      const contentState = convertFromRaw(rawContentState);
      return EditorState.createWithContent(contentState);
    } catch (e) {
      const contentState = ContentState.createFromText(text);
      return EditorState.createWithContent(contentState);
    }
  };
  const Item = ({ label, value, id }) => {
    if (id % 2 != 0 && bg == "#f9fafb") {
      bg = "white";
    } else {
      bg = "#f9fafb";
    }

    return (
      <div className="item" style={{ backgroundColor: bg }}>
        <p>{label}</p>
        <p style={{ color: "var(--dark-color)" }}>{value}</p>
      </div>
    );
  };
  return (
    <div className="desc">
      {/* <p className="text">{desc}</p> */}
      <Editor readOnly editorState={convertStringToEditorState(desc)} />
      <div className="desc_sub">
        <h3 className="title3">Overview</h3>
        <div className="items">
          {properties.map((item, id) => (
            <Item label={item.label} value={item.value} id={id} key={id + ""} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Descriptions;
