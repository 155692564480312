import React from 'react'
import { useSwiper } from 'swiper/react';
import RArrowIco from '../../../assets/icons/R_arrow_white.png'
import LArrowIco from '../../../assets/icons/L_arrow_black.png'
import './swiperBtns.css'



function SwiperBtns() {
    const swiper = useSwiper();
    return (
        <div className="arrow_container">
            <img
                src={LArrowIco}
                onClick={() => swiper.slidePrev()}
                className='l_arrow prev'
            />
            <img
                onClick={() => swiper.slideNext()}
                src={RArrowIco}
                className='r_arrow next' />
        </div>)
}

export default SwiperBtns