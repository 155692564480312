import React from 'react'
import BestSelling from './sections/bestSelling/BestSelling'
import CustomerChoices from './sections/customerChoices/CustomerChoices'
import Landing from './sections/landing/Landing'

function Home() {
    return (
        <div style={{overflow:'hidden'}} >
            <Landing />
            <BestSelling />
            <CustomerChoices />
        </div>
    )
}

export default Home