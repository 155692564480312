import React from "react";
import "./btns.css";

export const PrimaryBtn = ({ title, containerStyle, onClick }) => {
  return (
    <div onClick={onClick} style={containerStyle} className="primary">
      {title}
    </div>
  );
};

export const SecondaryBtn = ({ title, icon }) => {
  return (
    <div className="secondary">
      {title}
      {icon && <img src={icon} />}
    </div>
  );
};
