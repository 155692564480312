import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import Home from "../pages/home/Home";
import ProductDetails from "../pages/productDetails/ProductDetails";
import About from "../pages/about/About";
import HelpCenter from "../pages/helpCenter/HelpCenter";

function Router() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<HelpCenter />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Router;
