import React, { useState } from 'react'
import './checkBox.css'



function CheckBox({ label, labelStyle, ...props }) {
    const [checked, set] = useState(false)
    return (
        <label
            className='ckbx'

        >
            <input type="checkbox" {...props} checked={checked} onClick={() => set(!checked)} />
            <span className='checkmark'></span>
            <label style={labelStyle} className='title3'>{label}</label>
        </label>
    )
}

export default CheckBox