import React from "react";
import BricadoLogo from "../../assets/images/logos/bricado_logo_black.png";
import MailLogo from "../../assets/images/mail.png";
import InstaLogo from "../../assets/images/insta.png";
import CallLogo from "../../assets/images/call.png";
import "./helpCenter.css";

const HelpCenter = () => {
  return (
    <div className="help-container">
      <div className="help-image">
        <img src={BricadoLogo} alt="Help & Support" style={{ width: "100%" }} />
      </div>
      <div className="help-content">
        <div className="help-heading">Help & Support</div>
        <div className="help-paragraph">
          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            We're here to help! If you have any questions or need assistance,
            please don't hesitate to reach out to us through any of the
            following channels:
          </p>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="help-contact">
              <div className="help-icon">
                <img
                  src={CallLogo}
                  style={{ width: 20, height: 20 }}
                  alt="Call"
                />
              </div>
              <div className="help-contact-text">
                <a
                  href="tel:+917025575112"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                 +91 70255 75112
                </a>
              </div>
            </div>
            <div className="help-contact">
              <div className="help-icon">
                <img
                  src={MailLogo}
                  style={{ width: 20, height: 20 }}
                  alt="Mail"
                />
              </div>
              <div className="help-contact-text">
                <a
                  href="mailto:helpdesk.bricado@gmail.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  helpdesk.bricado@gmail.com
                </a>
              </div>
            </div>
            <div className="help-contact">
              <div className="help-icon">
                <img
                  src={InstaLogo}
                  style={{ width: 22, height: 22, objectFit: "contain" }}
                  alt="Instagram"
                />
              </div>
              <div className="help-contact-text">
                <a
                  href="https://instagram.com/bricado_official"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  bricado_official
                </a>
              </div>
            </div>
          </div>

          <p style={{ fontSize: "1.2em", marginTop: "20px" }}>
            Thank you for choosing Bricado. We look forward to serving you and
            helping you discover the best in quality and value.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
