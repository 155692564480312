import React from "react";
import BricadoLogo from "../../assets/images/logos/bricado_logo_black.png";
import MailLogo from "../../assets/images/mail.png";
import InstaLogo from "../../assets/images/insta.png";
import CallLogo from "../../assets/images/call.png";
import "./about.css"; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="container">
      <div className="image">
        <img src={BricadoLogo} alt="About Us" style={{ width: "100%" }} />
      </div>
      <div className="content">
        <div className="heading">About</div>
        <div className="paragraph">
          <p>
            Welcome to <strong>Bricado</strong>, where quality meets excellence.
            At Bricado, we pride ourselves on offering an unparalleled
            e-commerce experience. Our mission is to provide our customers with
            high-quality products that meet their needs and exceed their
            expectations.
          </p>
          <p>
            Our team is dedicated to sourcing the best products from around the
            world, ensuring that every item we offer is of the highest standard.
            We believe in building long-lasting relationships with our
            customers, and our commitment to excellence is reflected in every
            aspect of our business.
          </p>
        </div>
        <div className="contact">
          <div className="icon">
            <img src={CallLogo} style={{ width: 20, height: 20 }} alt="Call" />
          </div>
          <div className="contactText">
            <a
              href="tel:+917025575112"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              +91 70255 75112
            </a>
          </div>
        </div>
        <div className="contact">
          <div className="icon">
            <img src={MailLogo} style={{ width: 20, height: 20 }} alt="Mail" />
          </div>
          <div className="contactText">
            <a
              href="mailto:info.bricado@gmail.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              info.bricado@gmail.com
            </a>
          </div>
        </div>
        <div className="contact">
          <div className="icon">
            <img
              src={InstaLogo}
              style={{ width: 22, height: 22, objectFit: "contain" }}
              alt="Instagram"
            />
          </div>
          <div className="contactText">
            <a
              href="https://www.instagram.com/bricado__official/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              bricado__official
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
