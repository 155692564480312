import React from 'react'
import './bestSelling.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import Img1 from '../../../../assets/images/temp/bestSelling.png'
import Img2 from '../../../../assets/images/temp/bestSelling_2.png'
import Img3 from '../../../../assets/images/temp/bestSelling_3.png'
import Img4 from '../../../../assets/images/temp/bestSelling_4.png'

import ProductCard from '../../../../components/productCard/ProductCard';
import SwiperBtns from '../../swiperBtns/SwiperBtns';
function BestSelling() {



    return (
        <div className='best_selling' >
            <h1 className="title2">Best Selling Products</h1>
            <p className="desc">Check our best seller products right now</p>

            <Swiper
                spaceBetween={35}
                slidesPerView={window.screen.width>768?4: 1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                className='swiper_wrpr'
            >{[Img1, Img3, Img2, Img4, Img1, Img3, Img2, Img4,].map((item) => (
                <SwiperSlide>
                    <ProductCard product={item} />
                </SwiperSlide>

            ))}
                <SwiperBtns />
            </Swiper>




        </div>
    )
}

export default BestSelling