import React, { useEffect, useState } from "react";
import "./productDetails.css";
import Img from "../../assets/images/temp/bestSelling_2.png";
import Img2 from "../../assets/images/temp/bestSelling_3.png";
import Img3 from "../../assets/images/temp/bestSelling_4.png";

import StarIco from "../../assets/icons/star.png";
import CheckBox from "../../components/checkBox/CheckBox";
import { PrimaryBtn } from "../../components/btns/Btns";
import ProductTab from "./components/ProductTab";
import Descriptions from "./components/Descriptions";
import Reviews from "./components/Reviews";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { COLLECTION_NAMES } from "../../utils/constants";
import { db } from "../../utils/firebase";
import Loader from "../../components/loader/Loader";

function ProductDetails() {
  const imgs = [Img, Img2, Img3, Img];
  const [selectedImg, setSelectedImg] = useState();
  let { id } = useParams();
  const [selectedTab, setSelectedTab] = useState("");
  const [noOfProducts, setNoOfProducts] = useState(1);
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const buyHandler = () => {
    window.open(
      `https://wa.me/917025575112?text=Hello%2C%20I%20am%20interested%20in%20buying%${product.name}%20Book%20Pro%20${noOfProducts}%20Nos.`
    );
  };
  const getProductDetails = async () => {
    if (!id) return alert("Product not found");

    const docRef = doc(db, COLLECTION_NAMES.products, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setProduct({ ...docSnap.data(), id: docSnap.id });
      setSelectedImg(docSnap.data().images[0]);
      setLoading(false);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      alert("Product not found");
    }
  };
  useEffect(() => {
    getProductDetails();
  }, [id]);
  if (loading) return <Loader />;
  return (
    <div className="productDetails">
      <div className="sub">
        <div className="img_wrpr">
          <img src={selectedImg} className="main_img" />
          <div className="imgs">
            {product?.images.map((i) => (
              <img onClick={() => setSelectedImg(i)} src={i} />
            ))}
          </div>
        </div>
        <div className="dtls_wrpr">
          <h2 className="title2">{product.name}</h2>

          {/* <div className="rating_wrpr">
                        <img src={StarIco} />
                        <p className="desc">4.5</p>
                    </div> */}

          <div className="price_wrpr">
            <h2 className="title2" style={{color:'#5468e7'}}> ₹{product.discountPrice}</h2>
            {/* <p className="desc">$2,199.00</p> */}
            {/* <p className="desc" style={{ color: '#6CC9B7' }} >20% OFF</p> */}
          </div>

          {/* <div className="ppt_wrpr">
                        <h3 className='title' >Product color</h3>
                        <div className="wrpr">
                            {['#FFBA79', '#FFADD0', '#9A97ED', '#D1ECFD', '#C897E4', '#D1ECFD'].map(col =>
                                <div className='outer' style={{ borderColor: col }} >
                                    <div className='inner' style={{ backgroundColor: col }} />
                                </div>
                            )}
                        </div>
                    </div> */}

          {/* <div className="ppt_wrpr">
                        <h3 className='title' >Internal Storage</h3>
                        <div className="wrpr">
                            <CheckBox label={'256 GB'} />
                            <CheckBox label={'512 GB'} />
                            <CheckBox label={'1 TB'} />
                            <CheckBox label={'2 TB'} />
                            <CheckBox label={'512 GB'} />
                        </div>
                    </div> */}

          <div className="btn_container">
           {!product.comingSoon&& <div className="qty_wrpr">
              <div
                className="qty_btn"
                onClick={() =>
                  setNoOfProducts((prev) => (prev > 1 ? prev - 1 : 1))
                }
              >
                -
              </div>
              <p>{noOfProducts}</p>
              <div
                className="qty_btn"
                onClick={() => setNoOfProducts((prev) => prev + 1)}
              >
                +
              </div>
            </div>}

            {product.comingSoon ? (
              <p style={{ color: "red" }}>Currently unavailable</p>
            ) : (
              <PrimaryBtn
                onClick={buyHandler}
                containerStyle={{ width: "100%" }}
                title="Buy"
              />
            )}
          </div>
        </div>
      </div>
      <ProductTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div style={{ marginBottom: 50, marginTop: 30 }}>
        {selectedTab == "desc" && (
          <Descriptions
            desc={product.description}
            properties={product.properties}
          />
        )}
        {selectedTab == "review" && <Reviews />}
      </div>
    </div>
  );
}

export default ProductDetails;
