import React from 'react'
import './productCard.css'

import StarIco from '../../assets/icons/star.png'
import FavIco from '../../assets/icons/fav.png'
function ProductCard({ product }) {

    return (
        <div className='product_card'>
            <img src={product} />
            {/* <img src={FavIco} className='fav_ico' /> */}

            <div className="catBar">
                <p className='desc' >Electronics</p>
                <div style={{ flex: 1 }} />
                <img src={StarIco} />
                <p className='desc' >4.5</p>
            </div>
            <h3 className='title3' >Mac book pro 16</h3>
            <p className='title3' style={{ color: 'var(--primary-color)' }} >$1,725.00</p>
        </div>
    )
}

export default ProductCard