import { People } from "iconsax-react";
import React from "react";

const ProductTab = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="prod_tab">
      <div className="item" onClick={() => setSelectedTab("desc")}>
        <p
          className="iText"
          style={{
            ...(selectedTab == "desc" && { color: "var(--primary-color)" })
          }}
        >
          Descriptions
        </p>
        {selectedTab == "desc" && <div className="i_pattern"></div>}
      </div>

      {/* <div className='item'
                onClick={() => setSelectedTab('review')}
            >
                <p className='iText'
                    style={{ ...(selectedTab == 'review' && { color: 'var(--primary-color)' }) }}
                >Reviews</p>
                {selectedTab == 'review' && <div className='i_pattern'></div>}
            </div>
            <div style={{ flex: 1 }} />
            <div className='share_wrpr'
            >
                <People />
                <p className='iText'
                style={{marginLeft:10}}
                >Share</p>
            </div> */}
    </div>
  );
};

export default ProductTab;
