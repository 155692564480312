import { Refresh2, Star, Star1 } from 'iconsax-react'
import React, { useState } from 'react'
import { PrimaryBtn } from '../../../components/btns/Btns';
import CustomDropDown from '../../../components/dropDown/CustomDropDown';
import TextInput from '../../../components/Inputs/TextInput';


const Reviews = () => {

    const [sortOptions, setSortOptions] = useState([{ value: 'chocolate', label: 'Highest Price' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }])

    const RatingBarItem = ({ rating, value }) => {
        let percent = value / 100;
        let mValue = value
        if (value >= 1000) {
            mValue = (value / 1000) + 'k'
        }
        return (
            <div className="rating_bar_wrpr">
                <p>{rating}</p>
                <Star1 size={13} variant='Bold' color='#FFBC50' />
                <div className="bar">
                    <div style={{ height: 4, width: `${percent}%`, backgroundColor: '#6CC9B7', borderRadius: 10 }} />
                </div>
                <p className='desc' >{mValue}</p>

            </div>
        )
    }
    const CommentItem = () => {
        return (
            <div className='comment_item' >
                <div className="user_dtls">
                    <img src="https://cdn.pixabay.com/photo/2016/08/31/11/54/icon-1633249__340.png" alt="" />
                    <div className="sub">
                        <h3 className="title3">Daisy Murphy</h3>
                        <div className="desc">July, 23 2020</div>
                    </div>
                </div>
                <p className="desc">
                    Sony α, is a camera system introduced on 5 June 2006. It uses and expands upon Konica Minolta camera technologies, including the Minolta AF SLR lens mount…
                    <span>Read More</span>
                </p>
            </div>
        )
    }
    return (
        <div className='reviews' >
            <h3 className='title3' >Reviews</h3>
            <div className="over_all">
                <div className="sub_1">
                    <div className="rating_wrpr">
                        <Star1 variant='Bold' color='#FFBC50' />
                        <p>4.5 <span>/ 5.0</span></p>
                    </div>
                </div>
                <div className="sub_2">
                    <RatingBarItem rating='5.0' value={1000} />
                    <RatingBarItem rating='4.0' value={2000} />
                    <RatingBarItem rating='3.0' value={4000} />
                    <RatingBarItem rating='2.0' value={250} />
                    <RatingBarItem rating='1.0' value={100} />

                </div>
            </div>
            <div className="bottom">

                <div className="comments">

                    <div className="header">
                        <div className="title3">All Comments </div>
                        <div className="sort_wrpr">
                            <p className="title3">Sort by :</p>
                            <CustomDropDown
                                options={sortOptions}
                                defaultValue={sortOptions[0]}
                                onSelect={() => { }}
                                customHeaderStyle={{
                                    backgroundColor: 'var(--primary-color)',
                                    borderRadius: 5,
                                    color: 'white',
                                    fontSize: 12,
                                    padding: 3
                                }}
                                placeHolderStyle={{ color: 'white', fontSize: 10 }}

                            />

                        </div>
                    </div>
                    <div className="comment_wrpr">
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <div className='btn_wrpr' >
                            <div className="btn">
                                <Refresh2 color='#5468E7' size={15} />
                                <p>Load More Reviews</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="add_review">
                    <h3 className="title3">Choose your rating</h3>
                    <div className="star_wrpr">
                        <Star1 className='star' color='#FFBC50' variant='Bold' />
                        <Star1 className='star' color='#FFBC50' variant='Bold' />
                        <Star1 className='star' color='#959EAD' variant='linear' />
                        <Star1 className='star' color='#959EAD' variant='linear' />
                        <Star1 className='star' color='#959EAD' variant='linear' />
                    </div>
                    <div className="desc_wrpr">
                        <h3 className="title3">Your review</h3>
                        <textarea
                            placeholder='The product is...'
                            rows={10}
                        />
                    </div>
                    <PrimaryBtn title={'Submit review'} containerStyle={{ marginTop: 20, width: '80%', alignSelf: 'center' }} />
                </div>
            </div>
        </div>
    )
}

export default Reviews