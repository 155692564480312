import React, { Component, useState } from "react";
import "./navbar.css";

import BricadoLogo from "../../assets/images/logos/bricado_logo_white.png";
import { useNavigate } from "react-router-dom";
function NavBar() {
  const [isRegModal, setIsRegModal] = useState(true);
const navigate =useNavigate()
  const options = [
    { value: "chocolate", label: "Mobile and Tablets" },
    { value: "strawberry", label: "Wearables" },
    { value: "vanilla", label: "Laptops and gaming" }
  ];

  return (
    <div className="navbar">
      <a href="/">
        <img className="logo" src={BricadoLogo} />
        {/* <p
          style={{
            marginTop: 5,
            color: "white",
            fontSize: 12,
            letterSpacing: 2,
            width:200,
            textDecoration:'none'
          }}
        >
          WHERE QUALITY MEETS
        </p> */}
      </a>
      <ul>
      <li onClick={()=>navigate('/')} >Home</li>
        <li onClick={()=>navigate('/about')} >About</li>
        <li onClick={()=>navigate('/help')} >Help</li>

      </ul>
      {/* <h2 className='title'>Storezzz</h2> */}
      {/* <div className='search_wrpr'>
                <CustomDropDown options={options}
                    customHeaderStyle={dropDownStyle}
                    placeholder='All Categories'
                    placeHolderStyle={{ color: 'white', fontSize: 12 }}
                />
                <img src={SearchIco} className='searchIco' />
                <input placeholder='Search Something' />
            </div>
            <div className='last_wrpr' >
                <img src={CartIco} className='ico' />
                <img src={NotifIco} className='ico' />
                <img src={Message} className='ico' />
                <div className='line' />
                <img src={UserICo} className='ico' />
                <p className='login' >Login</p>
                <p className='or' >or</p>
                <p onClick={() => setIsRegModal(true)} >Register</p>
            </div> */}
      {/* <RegisterModal show={isRegModal} setShow={setIsRegModal} /> */}
    </div>
  );
}

export default NavBar;

const dropDownStyle = {
  backgroundColor: "#161D25",
  borderRadius: "50px",
  width: 158,
  height: 30,
  paddingLeft: 10
};
