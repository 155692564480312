import React, { useEffect, useState } from "react";
import "./customerChoices.css";
import Img1 from "../../../../assets/images/temp/bestSelling.png";
import Img2 from "../../../../assets/images/temp/bestSelling_2.png";
import Img3 from "../../../../assets/images/temp/bestSelling_3.png";
import Img4 from "../../../../assets/images/temp/bestSelling_4.png";

import StarIco from "../../../../assets/icons/star.png";
import { PrimaryBtn } from "../../../../components/btns/Btns";
import { COLLECTION_NAMES } from "../../../../utils/constants";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../../../utils/firebase";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";

function CustomerChoices() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filteredBiltis, setFilteredBiltis] = useState([]);
  const navigate = useNavigate();
  const getProducts = async () => {
    let tempData = [];
    const q = query(collection(db, COLLECTION_NAMES.products));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      tempData.push({
        ...doc.data(),
        id: doc.id
      });
    });
    setProducts(tempData);
    setFilteredBiltis(tempData);
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const PrdtCardSm = ({ product }) => {
    return (
      <div
        className="prdt_card"
        onClick={() => navigate("/product/" + product.id)}
      >
        <img src={product.images[0]} />
        <div className="txt_wrpr">
          {/* <div className="sub">
            <img src={StarIco} alt="" srcset="" />
                        <p className="desc">4.5</p>
          </div> */}
          <h3 className="title3">{product.name}</h3>
          <h3 className="price" style={{ color: "var(--primary-color)" }}>
            ₹{product.discountPrice}
          </h3>
          {/* <div style={{ flex: 1 }} /> */}
          <p className="desc">{product.category}</p>
        </div>
      </div>
    );
  };
  // if (loading) return <Loader />;
  return (
    <div className="cust_choices">
      <h1 className="title2">Customer choices</h1>
      <p className="desc">
        Check our best seller products on website right now
      </p>
      {loading ? (
        <Loader />
      ) : (
        <div className="prdts_wrpr">
          {products.map((item) => (
            <PrdtCardSm product={item} />
          ))}
        </div>
      )}
      {/* <PrimaryBtn title='View More Products' /> */}
    </div>
  );
}

export default CustomerChoices;
